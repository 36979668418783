<template>
  <div class="app-container" style="display:flex; flex-direction:column">
    <link href="https://api.mapbox.com/mapbox-gl-js/v3.1.2/mapbox-gl.css" rel="stylesheet">
    <div class="page-title">
      <h2>La parcelle</h2>
    </div>

    <!-- <el-card class="box-card"> -->
    <div class="filter-container">
      <!-- <el-button style="margin-left: auto" class="mr-15 filter-item" type="primary" icon="el-icon-plus" @click="navigateImpression">
        Imprimer
      </el-button> -->
    </div>
    <el-row>
      <el-col :span="6" style="margin-right:7px">
        <div class="grid-content bg-purple">
          <el-card class="box-card">
            <div slot="header" class="clearfix">
              <span style="font-weight:bold">Producteur</span>
              <!-- <el-button style="float: right; padding: 3px 0" type="text">Bouton</el-button> -->
            </div>
            <div class="text item" style="display: flex; align-items: center; flex-direction:column">
              <div v-if="currentProducteur.avatar !== undefined">
                <pan-thumb v-if="currentProducteur.avatar !== null" :image="currentProducteur.avatar" :height="'100px'" :width="'100px'" :hoverable="false">
                  <el-tag :key="currentProducteur.code" style="margin:5px" type="info">{{ currentProducteur.code }}</el-tag>
                  <span style="font-weight: bold; font-style: italic; margin-bottom: 5px">{{ currentProducteur.nom !== undefined ? currentProducteur.nom + ' ' + currentProducteur.prenom : '' }}</span>
                  <span v-if="currentProducteur.sexe !== undefined" style="font-style: italic;">{{ currentProducteur.sexe === 'm' ? 'Masculin' : 'Féminin' }}</span>
                  <!-- <el-table v-loading="loading" :data="persinfos" :show-header="false">
                      <el-table-column>
                        <template slot-scope="scope" style="background-color: bisque">
                          <span style="font-weight: bold;">{{ scope.row.libelle }}</span>
                        </template>
                      </el-table-column>
                      <el-table-column>
                        <template slot-scope="scope">
                          <span style="font-style: italic;">{{ scope.row.valeur }}</span>
                        </template>
                      </el-table-column>
                    </el-table> -->
                </pan-thumb>
                <div v-else>
                  <el-avatar icon="el-icon-user-solid" />
                </div>
              </div>
              <div v-else>
                <el-avatar icon="el-icon-user-solid" />
              </div>
              <el-tag :key="currentProducteur.code" style="margin:5px" type="info">{{ currentProducteur.code }}</el-tag>
              <span style="font-weight: bold; font-style: italic; margin-bottom: 5px">{{ currentProducteur.nom !== undefined ? currentProducteur.nom + ' ' + currentProducteur.prenom : '' }}</span>
              <span v-if="currentProducteur.sexe !== undefined" style="font-style: italic;">{{ currentProducteur.sexe === 'm' ? 'Masculin' : 'Féminin' }}</span>
              <div v-if="currentProducteur.nom !== undefined" class="user-follow">
                <el-button
                  :disabled="loading"
                  type="primary"
                  icon="upload"
                  style="width: 100%"
                  @click="imagecropperShow = true"
                >
                  {{ $t("user.changeAvatar") }}
                </el-button>
                <el-button
                  v-show="loading === false"
                  v-if="currentProducteur.avatar !== null"
                  type="danger"
                  style="width: 100%"
                  @click="deleteProdcteur"
                >
                  supprimer
                </el-button>
              </div>

              <image-cropper
                v-show="imagecropperShow"
                :key="imagecropperKey"
                :url="url"
                lang-type="fr"
                @close="close"
                @crop-upload-success="cropSuccess"
              />
            </div>
          </el-card>
        </div>
      </el-col>
      <el-col :span="16">
        <div class="grid-content bg-purple-light">
          <el-card class="box-card">
            <div slot="header" class="clearfix">
              <span style="font-weight: bold;">Autres informations</span>
              <span style="position: absolute; right: 100px">
                <el-button type="primary" icon="el-icon-edit" :disabled="parcelleEditDisable" @click="handleParcelleEditDialogue" />
              </span>
            </div>
            <el-tabs tab-position="top" @tab-click="handleClick">
              <el-tab-pane label="Caractéristiques">
                <!-- Caractéristiques -->
                <el-table v-loading="loading" :data="parcinfos" :show-header="false">
                  <el-table-column>
                    <template slot-scope="scope">
                      <span style="font-weight: bold;">{{ scope.row.libelle }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column>
                    <template slot-scope="scope">
                      <span style="font-style: italic;" v-html="scope.row.valeur " />
                    </template>
                  </el-table-column>
                </el-table>

              </el-tab-pane>
              <el-tab-pane label="Localité">
                <!-- Hiérarchie -->
                <el-table v-loading="loading" :data="organInfos" :show-header="false">
                  <el-table-column>
                    <template slot-scope="scope">
                      <span style="font-weight: bold;">{{ scope.row.libelle }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column>
                    <template slot-scope="scope">
                      <span style="font-style: italic;">{{ scope.row.valeur }}</span>
                    </template>
                  </el-table-column>
                </el-table>
              </el-tab-pane>
              <el-tab-pane label="Contrôle interne">
                <el-empty v-if="data.controle_interne === 'AUCUN'" description="Aucun controle interne" />
                <el-tag v-if="data.controle_interne !== 'AUCUN' && data.controle_interne_data" type="info" style="margin:10px">Les questions par catégorie</el-tag>
                <el-button v-if="data.controle_interne === 'TERMINER'" type="info" icon="el-icon-download" @click="generateContoleurPdf(parce_id,camp_id)">Télécharger Contrôle Interne PDF</el-button>
                <el-tabs v-if="data.controle_interne !== 'AUCUN' && data.controle_interne_data" tab-position="left">
                  <el-tab-pane v-for="controle in controles_internes" :key="controle.code" :label="controle.campagne_agricole?controle.campagne_agricole.split(' ')[1]:'INDISPONIBLE'">
                    <el-collapse v-if="data.controle_interne !== 'AUCUN' && data.controle_interne_data" v-model="activeName" accordion>
                      <el-collapse-item v-for="item in controle.categories" :key="item.libelle" :title="item.libelle">
                        <!-- <div>{{ item.libelle }}</div> -->
                        <el-table v-loading="loading" :data="item.questions" :show-header="true">
                          <el-table-column label="Question">
                            <template slot-scope="scope">
                              <span style="font-weight: bold;">{{ scope.row.libelle }}</span>
                            </template>
                          </el-table-column>
                          <el-table-column label="Réponse">
                            <template slot-scope="scope">
                              <span style="font-style: italic;">{{ scope.row.reponse ? scope.row.reponse : 'AUCUNE' }}</span>
                            </template>
                          </el-table-column>
                          <el-table-column label="Commentaire">
                            <template slot-scope="scope">
                              <span style="font-style: italic;">{{ scope.row.commentaire ? scope.row.commentaire : 'NO COMMENT' }}</span>
                            </template>
                          </el-table-column>
                        </el-table>
                      </el-collapse-item>
                    </el-collapse>
                  </el-tab-pane>
                </el-tabs>

                <!-- <el-table v-loading="loading" :data="controleInfos" :show-header="false">
                    <el-table-column>
                      <template slot-scope="scope" style="background-color: bisque">
                        <span style="font-weight: bold;">{{ scope.row.libelle }}</span>
                      </template>
                    </el-table-column>
                    <el-table-column>
                      <template slot-scope="scope">
                        <span style="font-style: italic;">{{ scope.row.valeur }}</span>
                      </template>
                    </el-table-column>
                  </el-table> -->
              </el-tab-pane>
              <!-- <el-tab-pane label="Audits">
                <el-empty v-if="!data.is_audit" description="Aucun audit" />
                <el-tag v-if="data.is_audit" type="info" style="margin:10px">Les questions par catégorie</el-tag>
                <el-tabs v-if="data.is_audit" tab-position="left">
                  <el-tab-pane v-for="audit in audits" :key="audit.code" :label="audit.campagne_agricole?audit.campagne_agricole.split(' ')[1]:'INDISPONIBLE'">
                    <el-collapse v-model="activeName" accordion>
                      <el-collapse-item v-for="item in audit.categories" :key="item.libelle" :title="item.libelle">
                        <div>{{ item.libelle }}</div>
                        <el-table v-loading="loading" :data="item.questions" :show-header="true">
                          <el-table-column label="Question">
                            <template slot-scope="scope">
                              <span style="font-weight: bold;">{{ scope.row.libelle }}</span>
                            </template>
                          </el-table-column>
                          <el-table-column label="Réponse">
                            <template slot-scope="scope">
                              <span style="font-style: italic;">{{ scope.row.reponse ? scope.row.reponse : 'AUCUNE' }}</span>
                            </template>
                          </el-table-column>
                          <el-table-column label="Commentaire">
                            <template slot-scope="scope">
                              <span style="font-style: italic;">{{ scope.row.commentaire ? scope.row.commentaire : 'NO COMMENT' }}</span>
                            </template>
                          </el-table-column>
                        </el-table>
                      </el-collapse-item>
                    </el-collapse>
                  </el-tab-pane>
                </el-tabs>
              </el-tab-pane> -->

              <el-tab-pane label="historique">
                <!-- Historiques -->
                <el-table :data="historiques" border fit highlight-current-row style="width: 100%">
                  <el-table-column align="center" label="Année" min-width="160px">
                    <template slot-scope="scope">
                      <template v-if="scope.row.edit">
                        <el-input v-model="scope.row.annee" size="small" />
                      </template>
                      <span v-else>{{ scope.row.annee }}</span>
                    </template>
                  </el-table-column>

                  <el-table-column align="center" label="Cultures pratiquées" min-width="200px">
                    <template slot-scope="scope">
                      <template v-if="scope.row.edit">
                        <el-input v-model="scope.row.culture_pratique" size="small" />
                      </template>
                      <span v-else>{{ scope.row.culture_pratique }}</span>
                    </template>
                  </el-table-column>

                  <el-table-column align="center" label="Usage angrais" min-width="100px">
                    <template slot-scope="scope">
                      <template v-if="scope.row.edit">
                        <el-select v-if="scope.row.edit" v-model="scope.row.usage_angrais" style="width: 100%">
                          <el-option
                            :label="$t('general.yes')"
                            :value="true"
                          />
                          <el-option
                            :label="$t('general.no')"
                            :value="false"
                          />
                        </el-select>
                      </template>
                      <span v-else>{{ scope.row.usage_angrais === true ? 'OUI' : 'NON' }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column align="center" label="Usage OGM" min-width="100px">
                    <template slot-scope="scope">
                      <template v-if="scope.row.edit">
                        <el-select v-if="scope.row.edit" v-model="scope.row.semence_ogm" style="width: 100%">
                          <el-option
                            :label="$t('general.yes')"
                            :value="true"
                          />
                          <el-option
                            :label="$t('general.no')"
                            :value="false"
                          />
                        </el-select>
                      </template>
                      <span v-else>{{ scope.row.semence_ogm === true ? 'OUI' : 'NON' }}</span>
                    </template>
                  </el-table-column>
                  <!--el-table-column align="center" :label="$t('general.actions')" width="200">
                    <template slot-scope="scope">
                      <el-button
                        v-if="scope.row.edit"
                        size="small"
                        icon="el-icon-refresh"
                        type="warning"
                        @click="cancelHistoriqueEdit(scope.row)"
                      />
                      <el-button
                        v-if="scope.row.edit"
                        type="success"
                        size="small"
                        @click="confirmHistoriqueEdit(scope.row, scope.$index)"
                      >
                        Ok
                      </el-button>
                      <el-button
                        v-else
                        type="primary"
                        size="small"
                        icon="el-icon-edit"
                        @click="scope.row.edit=!scope.row.edit"
                      />
                      <el-button v-if="scope.row.edit === false" type="danger" size="small" icon="el-icon-delete" @click="deleteHistorique(scope.row.id)" />
                    </template>
                  </el-table-column-->
                </el-table>

                <!--                Historiques-->
                <!--                <el-form ref="historiqueForm" :inline="true" :model="{histForm}" label-position="top" class="demo-form-inline">-->
                <!--                  <div v-for="(historique, counter) in histForm" :key="counter">-->
                <!--                    <el-form-item :label="$t('parcelle.annee')" prop="annee" style="width: 100px">-->
                <!--                      <el-input v-model="historique.annee" placeholder="Annee" />-->
                <!--                    </el-form-item>-->
                <!--                    <el-form-item :label="$t('parcelle.culture')" prop="culture" style="width: 250px">-->
                <!--                      <el-input v-model="historique.culture_pratique" placeholder="Culture" />-->
                <!--                    </el-form-item>-->
                <!--                    <el-form-item :label="$t('parcelle.angrais')" prop="angrais" style="width: 150px">-->
                <!--                      <el-select v-model="historique.usage_angrais" style="width: 100%" placeholder="Usage Angrais">-->
                <!--                        <el-option-->
                <!--                          :label="$t('general.yes')"-->
                <!--                          :value="true"-->
                <!--                        />-->
                <!--                        <el-option-->
                <!--                          :label="$t('general.no')"-->
                <!--                          :value="false"-->
                <!--                        />-->
                <!--                      </el-select>-->
                <!--                    </el-form-item>-->
                <!--                    <el-form-item :label="$t('parcelle.semence_ogm')" prop="semence_ogm" style="width: 150px">-->
                <!--                      <el-select v-model="historique.semence_ogm" style="width: 100%" placeholder="semence ogm">-->
                <!--                        <el-option-->
                <!--                          :label="$t('general.yes')"-->
                <!--                          :value="true"-->
                <!--                        />-->
                <!--                        <el-option-->
                <!--                          :label="$t('general.no')"-->
                <!--                          :value="false"-->
                <!--                        />-->
                <!--                      </el-select>-->
                <!--                    </el-form-item>-->
                <!--                    <el-button type="danger" icon="el-icon-delete" circle @click="deleteHistoriqueForm" />-->
                <!--                  </div>-->
                <!--                  <el-button v-if="(histForm !== null ? histForm.length : 0) !== 0" :loading="createHistoriqueLoading" size="small" style="position: absolute; right: 0" type="primary" @click="createHistorique">-->
                <!--                    sauvergarder-->
                <!--                  </el-button>-->
                <!--                </el-form>-->
                <!--                <el-button :disabled="disableHistoryAdd || (historiques !== null ? historiques.length : 0) + (histForm !== null ? histForm.length : 0) === 3" type="primary" icon="el-icon-plus" circle @click="addHistorique" /> <span v-if="!disableHistoryAdd && (historiques !== null ? historiques.length : 0) + (histForm !== null ? histForm.length : 0) !== 3"> ( cliquez sur + pour ajouter une historique) </span>-->
              </el-tab-pane>
              <el-tab-pane label="Cartographie">
                <div v-if="coordonnees_parcelle.length>0 && coordonnees_parcelle[0]!=null">
                  <div id="map-container">

                    <div id="mapContainer" />

                  </div>
                  <button id="zoomto" class="btn-control">Voir la parcelle</button>
                </div>
                <div>
                  <el-tag style="margin:5px" type="info">Cette parcelle n'as pas de coordonnés</el-tag>
                </div>

              </el-tab-pane>
              <el-tab-pane label="Plan parcelle">
                <div v-if="parcelle.plan_zone" align="center">
                  <img :src="parcelle.plan_zone" alt="" width="200px">
                </div>
                <div v-if="!parcelle.plan_zone">
                  <el-tag style="margin:5px" type="info">Cette parcelle n'as pas de plan parcelle</el-tag>
                </div>
              </el-tab-pane>
              <el-tab-pane label="Collectes">
                <el-table :data="recoltes" border fit highlight-current-row style="width: 100%">
                  <el-table-column align="center" label="Campagne" min-width="200px">
                    <template slot-scope="scope">
                      <span>{{ scope.row.campagne_agricole.code }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column align="center" label="Code" max-width="200px">
                    <template slot-scope="scope">
                      <span>{{ scope.row.code }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column align="center" label="Poids total (tonne(s))" min-width="200px">
                    <template slot-scope="scope">
                      <span>{{ scope.row.poids_total }}</span>
                    </template>
                  </el-table-column>

                  <el-table-column align="center" label="Commentaires" min-width="150px">
                    <template slot-scope="scope">
                      <span>{{ scope.row.commentaire }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column align="center" label="Lots" min-width="150px">
                    <template slot-scope="scope">
                      <el-tag v-for="lot in scope.row.lots" :key="lot.code" style="margin:5px"><a @click="getLotLink(lot.id)">{{ lot.code }}</a></el-tag>
                    </template>
                  </el-table-column>
                  <el-table-column align="center" :label="$t('sacs.nombreSacs')" min-width="150px">
                    <template slot-scope="scope">
                      <span>{{ scope.row.nbrs_sac }}</span>
                    </template>
                  </el-table-column>
                  <!-- <el-table-column align="center" :label="1==1 ? $t('general.actions') : $t('general.suppressionDefinitive')" width="200">
                      <template slot-scope="scope">
                        <el-button type="primary" size="small" icon="el-icon-view" style="margin-right:10px;" @click="navigateParcelle(scope.row.id)" />
                      </template>
                    </el-table-column> -->
                </el-table>
              </el-tab-pane>
              <el-tab-pane label="Produits">
                <!-- Historiques -->
                <el-table :data="data.produits" border fit highlight-current-row style="width: 100%">
                  <el-table-column align="center" label="Nom produit" min-width="150px">
                    <template slot-scope="scope">
                      <span>{{ scope.row.nom }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column align="center" label="Description produit" min-width="150px">
                    <template slot-scope="scope">
                      <span>{{ scope.row.description }}</span>
                    </template>
                  </el-table-column>
                  <!-- <el-table-column align="center" :label="1==1 ? $t('general.actions') : $t('general.suppressionDefinitive')" width="200">
                      <template slot-scope="scope">
                        <el-button type="primary" size="small" icon="el-icon-view" style="margin-right:10px;" @click="navigateParcelle(scope.row.id)" />
                      </template>
                    </el-table-column> -->
                </el-table>
              </el-tab-pane>
            </el-tabs>
          </el-card>
        </div>
      </el-col>
    </el-row>
    <el-dialog
      title="Modification de parcelles"
      :visible.sync="parcelleEditDialog"
      :before-close="handleCloseCampagneagricoleCreateDraw"
      width="72%"
      center
    >
      <h3>{{ currentParcelle.code }}</h3>
      <el-form ref="parcelForm" :inline="true" :model="currentParcelle" label-position="top" class="demo-ruleForm">
        <el-form-item
          prop="village_id"
          :label="$t('parcelle.village_id')"
          :rules="[
            { required: true, message: 'veuillez renseigner ce champ', trigger: 'blur' },
          ]"
        >
          <el-select v-model="currentParcelle.village_id" class="filter-item" :placeholder="$t('village.village')" style="width: 100%;" prop="village_id">
            <el-option selected :value="currentParcelle.village_id" :label="currentParcelle.village" />
            <el-option
              v-for="(village, index) in villages"
              :key="index"
              :v-show="currentParcelle.village_id !== village.id"
              :label="village.nom | uppercaseFirst"
              :value="village.id"
              :disabled="!village.is_active"
            />
          </el-select>
        </el-form-item>
        <el-form-item
          prop="superficie"
          :label="$t('parcelle.superficie')"
          :rules="[
            { required: true, message: $t('parcelle.SuperficieRequired'), trigger: 'blur' },
            { type: 'number', message: 'Veuillez entrer un nombre', trigger: ['blur', 'change'] }
          ]"
        >
          <el-input-number v-model="currentParcelle.superficie" :controls="false" style="width: 100%" :min="0" controls-position="right" :placeholder="$t('parcelle.superficie')" required />
        </el-form-item>
        <el-form-item :label="$t('parcelle.acc_saison')" prop="saison">
          <el-input-number v-model="currentParcelle.acc_saison" :controls="false" style="width: 100%" :min="0" controls-position="right" :placeholder="$t('parcelle.acc_saison')" />
        </el-form-item>
        <el-form-item :label="$t('parcelle.dist_hab')" prop="distance">
          <el-input-number v-model="currentParcelle.dist_hab" :controls="false" style="width: 100%" :min="0" controls-position="right" :placeholder="$t('parcelle.dist_hab')" />
        </el-form-item>
        <el-form :inline="true" class="demo-form-inline">
          <el-form-item :label="$t('parcelle.nbr_arb')" prop="arbre">
            <el-input-number v-model="currentParcelle.nbr_arb" :controls="false" style="width: 100%" :min="0" controls-position="right" :placeholder="$t('parcelle.nbr_arb')" />
          </el-form-item>
          <el-form-item :label="$t('parcelle.riviere')" prop="riviere">
            <el-input-number v-model="currentParcelle.riviere" :controls="false" style="width: 100%" :min="0" controls-position="right" :placeholder="$t('parcelle.riviere')" />
          </el-form-item>
          <el-form-item :label="$t('parcelle.date_dernier_usage')" prop="date_usage">
            <el-input v-model="currentParcelle.date_dernier_usage" :placeholder="$t('parcelle.date_dernier_usage')" />
          </el-form-item>
          <el-form-item :label="$t('parcelle.observation')" prop="observation">
            <el-input v-model="currentParcelle.observation" prefix=" " type="textarea" :placeholder="$t('parcelle.observation')" />
          </el-form-item>
        </el-form>
        <el-form :inline="true" class="demo-form-inline">
          <el-form-item :label="$t('parcelle.statut')" prop="statut">
            <el-input v-model="currentParcelle.statut" style="width: 100%" :min="0" controls-position="right" :placeholder="$t('parcelle.statut')" />
          </el-form-item>
          <el-form-item :label="$t('parcelle.libelle')" prop="libelle">
            <el-input v-model="currentParcelle.libelle" style="width: 100%" :min="0" controls-position="right" :placeholder="$t('parcelle.libelle')" />
          </el-form-item>
          <el-form-item :label="$t('parcelle.zone_tp')" prop="zone">
            <el-select v-model="currentParcelle.zone_tp" style="width: 100%" :placeholder="$t('parcelle.zone_tp')">
              <el-option
                :label="$t('general.yes')"
                :value="true"
              />
              <el-option
                :label="$t('general.no')"
                :value="false"
              />
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('parcelle.dist_zn_tp')" prop="distance_ztp">
            <el-input-number v-model="currentParcelle.dist_zn_tp" :controls="false" style="width: 100%" :min="0" controls-position="right" :placeholder="$t('parcelle.dist_zn_tp')" />
          </el-form-item>
          <el-form-item :label="$t('parcelle.cult_zn_tp')" prop="cult_zn_tp">
            <el-input v-model="currentParcelle.cult_zn_tp" type="text" :placeholder="$t('parcelle.cult_zn_tp')" />
          </el-form-item>
        </el-form>
        <el-form :inline="true" class="demo-form-inline">
          <el-form-item :label="$t('parcelle.visa_ci')" prop="visa_ci">
            <el-select v-model="currentParcelle.visa_ci" :controls="false" style="width: 100%" :placeholder="$t('parcelle.visa_ci')">
              <el-option
                :label="$t('general.yes')"
                :value="true"
              />
              <el-option
                :label="$t('general.no')"
                :value="false"
              />
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('parcelle.visa_prod')" prop="visa_prod">
            <el-select v-model="currentParcelle.visa_prod" style="width: 100%" :placeholder="$t('parcelle.visa_prod')">
              <el-option
                :label="$t('general.yes')"
                :value="true"
              />
              <el-option
                :label="$t('general.no')"
                :value="false"
              />
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('parcelle.produits')" prop="produits">
            <el-select v-model="produitsAffecte" value-key="id" multiple placeholder="Select">
              <el-option
                v-for="(item, index) in produits"
                :key="index"
                style="width: 100%"
                :label="item.nom"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
        </el-form>
        <el-button @click="parcelleEditDialog = false">Annuler</el-button>
        <el-button type="primary" @click="updateParcelle">
          {{ $t('general.saving') }}
        </el-button>
      </el-form>
    </el-dialog>

    <!-- <h3>Autres informations</h3> -->

    <!-- <el-table v-loading="loading" :data="mininfos" :show-header="false">
        <el-table-column>
          <template slot-scope="scope" style="background-color: bisque">
            <span style="font-weight: bold;">{{ scope.row.name1 }}</span>
          </template>
        </el-table-column>
        <el-table-column>
          <template slot-scope="scope">
            {{ scope.row.val1 }}
          </template>
        </el-table-column>
        <el-table-column>
          <template slot-scope="scope">
            <span style="font-weight: bold">{{ scope.row.name2 }}</span>
          </template>
        </el-table-column>
        <el-table-column>
          <template slot-scope="scope">
            {{ scope.row.val2 }}
          </template>
        </el-table-column>
      </el-table> -->
    <!-- <table v-if="data !== null" style="border : 1px solid #000000; width:100%" cellspacing="0">
        <tr>
          <td style="border: 1px solid #000;">Code UP</td>
          <td style="border: 1px solid #000; width: 200px;">{{ data.code }}</td>
          <td style="border: 1px solid #000;">Nom du producteur</td>
          <td style="border: 1px solid #000; width: 200px;">{{ data.nom + ' ' + data.prenom }}</td>
        </tr>
        <tr>
          <td style="border: 1px solid #000;">Zone</td>
          <td style="border: 1px solid #000; width: 200px;">{{ }}</td>
          <td style="border: 1px solid #000;">Sexe (M/F)</td>
          <td style="border: 1px solid #000; width: 200px;">{{ data.sexe | uppercaseFirst }}</td>
        </tr>
        <tr>
          <td style="border: 1px solid #000;">Commune</td>
          <td style="border: 1px solid #000; width: 200px;">{{ }}</td>
          <td style="border: 1px solid #000;">Groupement</td>
          <td style="border: 1px solid #000; width: 200px;">{{ nomGroupements[data.groupement_id] }}</td>
        </tr>
        <tr>
          <td style="border: 1px solid #000;">Village</td>
          <td style="border: 1px solid #000; width: 200px;">{{ nomVillages[data.village_id] }}</td>
          <td style="border: 1px solid #000;">Code Producteur</td>
          <td style="border: 1px solid #000; width: 200px;">{{ data.code }}</td>
        </tr>
      </table> -->

    <!-- </el-card> -->
    <!-- <h4>Liste des Parcelles du producteur</h4>
    <el-card class="box-card filter-card just-for-admin">
      <el-table :data="parcelles" border fit highlight-current-row style="width: 100%">
        <el-table-column align="center" label="Code" min-width="200px">
          <template slot-scope="scope">
            <span>{{ scope.row.code }}</span>
          </template>
        </el-table-column>

        <el-table-column align="center" :label="$t('parcelle.superficie')" min-width="150px">
          <template slot-scope="scope">
            <span>{{ scope.row.superficie }}</span>
          </template>
        </el-table-column>

        <el-table-column align="center" :label="$t('parcelle.nbr_arb')" min-width="150px">
          <template slot-scope="scope">
            <span>{{ scope.row.nbr_arb }}</span>
          </template>
        </el-table-column>

        <el-table-column align="center" :label="1==1 ? $t('general.actions') : $t('general.suppressionDefinitive')" width="200">
          <template>
            <el-button type="primary" size="small" icon="el-icon-view" style="margin-right:10px;" @click="navigateParcelle" />
          </template>
        </el-table-column>
      </el-table>
    </el-card> -->
    <!--img
      style="margin-top:auto; align-self:bottom"
      width="100%"
      height="100px"
      src="https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fwww.cashadvance6online.com%2Fdata%2Farchive%2Fimg%2F3012921946.png&f=1&nofb=1"
      alt="grass bottom"
    -->
  </div>

</template>

<script>
// import Pagination from '@/components/Pagination'; // Pagination
import Resource from '@/api/resource';
import waves from '@/directive/waves'; // Directives
import permission from '@/directive/permission'; //  directive Permission
import role from '@/directive/role'; // Directives Role
import checkPermission from '@/utils/permission'; // Vérification Permission
import checkRole from '@/utils/role'; // Vérification Roles
import moment from 'moment';
import mapboxgl from 'mapbox-gl';
import PanThumb from '@/components/PanThumb';
import ImageCropper from '@/components/ImageCropper';

import * as turf from '@turf/turf';

// const producteursResource = new Resource('producteurs');
const parcelleResource = new Resource('parcelles');
const campagnesResource = new Resource('campagne_agricoles');
const historiqueRessource = new Resource('historiques');
const produitResource = new Resource('produits');
const groupementResource = new Resource('groupements');
const villageResource = new Resource('villages');
const recoltesResource = new Resource('parcelle-recoltes');
const parcelleControle = new Resource('parcelle-controles_interne');
const parcelleAudit = new Resource('parcelle-audits');
const controleInterneRessourcePdf = new Resource('parcelle/controle_interne');

export default {
  name: 'ParcelleInfo',
  components: { PanThumb, ImageCropper },
  directives: { waves, permission, role },
  data() {
    return {
      parce_id: null,
      camp_id: null,
      parcelle: null,
      data: {},
      date: '',
      parcelleEditDisable: true,
      fullscreenLoading: true,
      historiqueDeleteDialogue: false,
      createHistoriqueLoading: false,
      parcelleEditDialog: false,
      parcelles: null,
      parcelle_id: null,
      printing: false,
      produits: null,
      campagnes_agricoles: [],
      list: null,
      total: 0,
      accessToken: 'pk.eyJ1IjoiZGV2b25lc295IiwiYSI6ImNsMTR0c2Y0MDBkdWIzZHF6cjk5OWJ1NnMifQ.cEu2E93WCC2rY74DEjqixg',
      recoltes: [],
      controles_internes: [],
      audits: [],
      produitsAffecte: [],
      histForm: [],
      historiques: null,
      disableHistoryAdd: false,
      sexe: '',
      image: 'https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg',
      nomVillages: null,
      nomGroupements: null,
      query: {
        page: 1,
        limit: this.$limit,
      },
      loading: true,
      downloading: false,
      villages: null,
      groupements: null,
      activeList: 'disponibles',
      producteurCreating: false,
      editing: false,
      parcinfos: null,
      currentParcelle: {},
      organInfos: null,
      controleInfos: null,
      currentProducteur: {},
      dialogCreateProducteur: false,
      currentProducteurId: 0,
      rules: {
        nom: [{ required: true, message: this.$t('producteur.NomRequired'), trigger: 'blur' }],
        prenom: [{ required: true, message: this.$t('producteur.PrenomRequired'), trigger: 'blur' }],
        numero: [{ required: true, message: this.$t('producteur.NumeroRequired'), trigger: 'blur' }],
        adresse: [{ required: true, message: this.$t('producteur.AdresseRequired'), trigger: 'blur' }],
      },
      fileList: [],
      mininfos: [],
      imagecropperShow: false,
      imagecropperKey: 0,
      userAvatar: '',
      userid: '',
      url: '',
      coordonnees_parcelle: [],
    };
  },
  computed: {
    // eslint-disable-next-line vue/no-dupe-keys
    camp_id() {
      return this.$store.getters.camp_id;
    },
  },
  watch: {
    camp_id(val, oldval){
      this.getParcelle(val);
    },
  },
  created() {
    this.getGroupementsList();
    this.getVillagesList();
    this.getCampagnesList();
    this.getParcelle();
    this.getProduitsList();
  },
  methods: {
    async generateContoleurPdf(id, camp_id){
      var { data } = await controleInterneRessourcePdf.getControleurPdf(id, camp_id);
      window.open(data, '_blank');
      console.log('PDF : ', id, camp_id);
    },
    async getCampagnesList(){
      const { data } = await campagnesResource.list();
      console.log('CAMPAGNE RECUPERER', data);
      this.campagnes_agricoles = [];
      data.forEach((camp) => {
        this.campagnes_agricoles.push({ 'value': camp.id, 'label': camp.periode });
      });
    },
    cropSuccess() {
      this.imagecropperShow = false;
      this.imagecropperKey = this.imagecropperKey + 1;
      this.$message({
        type: 'success',
        message: 'Mise à jours avec succès',
      });
      this.getProducteur();
    },
    close() {
      this.imagecropperShow = false;
    },
    handleClick(tab, event) {
      console.log('the tab', tab.label);
      if (tab.label === 'Cartographie') {
        mapboxgl.accessToken = this.accessToken;

        const nav = new mapboxgl.NavigationControl();

        // const v2 = new mapboxgl.LngLatBounds([1.1868621, 6.2061716], [1.1872066, 6.2062775], [1.1875557, 6.2048793], [1.1870624, 6.2048941]);

        const map = new mapboxgl.Map({
          container: 'mapContainer',
          style: 'mapbox://styles/mapbox/streets-v12',
          center: [1.1868609, 6.2056304],
          zoom: 15,
        });
        const geojson = {
          'type': 'FeatureCollection',
          'features': [
            {
              'type': 'Feature',
              'geometry': {
                'type': 'LineString',
                'properties': {},
                'coordinates': this.coordonnees_parcelle,
              },
            },
          ],
        };

        /* [1.1868621, 6.2061716],
                  [1.1872066, 6.2062775],
                  [1.1875557, 6.2048793],
                  [1.1870624, 6.2048941],
                  [1.1868621, 6.2061716],
                  [1.1868621, 6.2061716], */
        map.on('load', () => {
          map.addSource('LineString', {
            'type': 'geojson',
            'data': geojson,
          });

          map.addLayer({
            'id': 'LineString',
            'type': 'line',
            'source': 'LineString',
            'layout': {
              'line-join': 'round',
              'line-cap': 'round',
            },
            'paint': {
              'line-color': '#ec1232',
              'line-width': 2,
            },
          });
          document.getElementById('zoomto').addEventListener('click', () => {
          // Geographic coordinates of the LineString
            const coordinates = geojson.features[0].geometry.coordinates;

            // Create a 'LngLatBounds' with both corners at the first coordinate.
            const bounds = new mapboxgl.LngLatBounds(
              coordinates[0],
              coordinates[0]
            );

            // Extend the 'LngLatBounds' to include every coordinate in the bounds result.
            for (const coord of coordinates) {
              bounds.extend(coord);
            }

            map.fitBounds(bounds, {
              padding: 20,
            });
          });
        });

        map.addControl(nav, 'top-right');
        // eslint-disable-next-line no-undef
        // var polygon = turf.polygon([[[1.1868621, 6.2061716], [1.1872066, 6.2062775], [1.1875557, 6.2048793], [1.1870624, 6.2048941], [1.1868621, 6.2061716]]]);
        var polygon = turf.polygon([this.coordonnees_parcelle]);
        // eslint-disable-next-line no-undef
        var area = turf.area(polygon);
        console.log('The area', area);
        // new mapboxgl.Marker()
        //   .setLngLat([1.1868621, 6.2061716])
        //   .addTo(map);
        // new mapboxgl.Marker()
        //   .setLngLat([1.1872066, 6.2062775])
        //   .addTo(map);
        // new mapboxgl.Marker()
        //   .setLngLat([1.1875557, 6.2048793])
        //   .addTo(map);
        // new mapboxgl.Marker()
        //   .setLngLat([1.1870624, 6.2048941])
        //   .addTo(map);
        //
        // var checking_style_status = false;
        // map.on('styledata', function(e) {
        //   if (checking_style_status){
        //     // If already checking style status, bail out
        //     // (important because styledata event may fire multiple times)
        //     return;
        //   } else {
        //     checking_style_status = true;
        //     this.check_style_status(map, checking_style_status);
        //   }
        // });

        // new mapboxgl.LngLatBounds([1.1868621, 6.2061716], [1.1872066, 6.2062775], [1.1875557, 6.2048793], [1.1870624, 6.2048941]);
        const geolocate = new mapboxgl.GeolocateControl({
          positionOptions: {
            enableHighAccuracy: true,
          },
          trackUserLocation: true,
        });

        map.addControl(geolocate, 'top-right');
      }
    },
    // check_style_status(map, checking_style_status) {
    //   if (map.isStyleLoaded()) {
    //     checking_style_status = false;
    //     map._container.trigger('map_style_finally_loaded');
    //   } else {
    //     // If not yet loaded, repeat check after delay:
    //     setTimeout(function() {
    //       this.check_style_status(map, checking_style_status);
    //     }, 200);
    //   }
    // },
    handleCloseCampagneagricoleCreateDraw(done) {
      this.$confirm(this.$t('general.closingWarningText'))
        .then(_ => {
          done();
        })
        .catch(_ => {});
    },
    addHistorique(){
      this.histForm.push({
        annee: '',
        culture_pratique: '',
        usage_angrais: null,
        semence_ogm: null,
      });
      this.histLengh = this.historiques !== null ? this.historiques.length : 0;
    },
    deleteHistoriqueForm(counter){
      this.histForm.splice(counter, 1);
    },
    createHistorique(){
      this.$refs['historiqueForm'].validate((valid) => {
        if (valid) {
          this.createHistoriqueLoading = true;
          this.histForm.forEach((historique) => {
            historique.parcelle_id = this.parcelle.id;
            historiqueRessource.store(historique)
              .then((response) => {
                this.$message({
                  message: 'Historique ajouté avec succès',
                  type: 'success',
                });
                this.getParcelle();
              })
              .catch((error) => {
                console.log(error);
              })
              .finally(() => {
                this.disableHistoryAdd = (this.historiques !== null ? this.historiques.length : 0) === 3;
              });
          });
          this.createHistoriqueLoading = false;
          this.histForm = [];
        } else {
          this.$message({
            message: 'Formulaire non valide',
            type: 'warn',
          });
        }
      });
    },
    handleParcelleEditDialogue(){
      this.parcelleEditDialog = true;
      this.currentParcelle = this.data.parcelle;
      this.produitsAffecte = [];
      this.currentParcelle.produits.forEach((item) => {
        this.produitsAffecte.push(item.id === undefined ? item : item.id);
      });
      this.$nextTick(() => {
        this.$refs['parcelForm'].clearValidate();
      });
    },
    updateParcelle() {
      this.$refs['parcelForm'].validate((valid) => {
        if (valid) {
          this.currentParcelle.produits = this.produitsAffecte;
          const parcelle = (({
            superficie,
            libelle,
            nbr_arb,
            date_dernier_usage,
            dist_hab,
            riviere,
            acc_saison,
            zone_tp,
            dist_zn_tp,
            cult_zn_tp,
            observation,
            produits,
          }) => ({
            superficie,
            libelle,
            nbr_arb,
            date_dernier_usage,
            dist_hab,
            riviere,
            acc_saison,
            zone_tp,
            dist_zn_tp,
            cult_zn_tp,
            observation,
            produits,
          }))(this.currentParcelle);

          console.log('Parcel to be affected', parcelle);
          parcelleResource
            .update(this.currentParcelle.id, parcelle)
            .then(async(response) => {
              if (response.success){
                this.$message({
                  message: this.$t('parcelle.isupdatedSuccessfully'),
                  type: 'success',
                  duration: 5 * 1000,
                });
              } else {
                this.$message({
                  message: response.message,
                  type: 'info',
                  duration: 5 * 1000,
                });
              }
            })
            .catch(error => {
              console.log(error);
            })
            .finally(() => {
              this.parcelleEditDialog = false;
              this.currentParcelle = {};
              this.produitsAffecte = [];
            });
        } else {
          console.log('Formulaire non valide !!');
          return false;
        }
      });
    },
    async getProduitsList() {
      const { data } = await produitResource.list();
      this.produits = await data;
    },
    cancelHistoriqueEdit(row) {
      row.culture_pratique = row.originalCulturePratique;
      row.edit = false;
      this.$message({
        message: 'Modification annulée',
        type: 'info',
      });
    },
    confirmHistoriqueEdit(row, index) {
      row.edit = false;
      row.originalCulturePratique = row.culture_pratique;
      this.historiques[index].parcelle_id = this.parcelle.id;
      const historiques = (({ parcelle_id, annee, culture_pratique, semence_ogm, usage_angrais }) => ({ parcelle_id, annee, culture_pratique, semence_ogm, usage_angrais }))(this.historiques[index]);
      console.log('HERE IS THE ROW OBTAINS ', historiques, this.historiques[index].parcelle_id);
      historiqueRessource.update(this.historiques[index].id, historiques)
        .then(response => {
          this.$message({
            message: response.message,
            type: 'success',
          });
        })
        .catch(error => {
          console.log(error);
        })
        .finally(() => {

        });
    },
    deleteHistorique(id) {
      this.$confirm(this.$t('historique.deleteWarn') + this.$t('general.deleteContinue'), 'Warning', {
        confirmButtonText: 'Oui',
        cancelButtonText: this.$t('general.cancel'),
        type: 'warning',
      }).then(() => {
        if (checkRole(['admin']) || checkPermission(['supprimer parcelle'])) {
          this.$swal({
            title: this.$t('general.SureQuestion'),
            text: this.$t('general.irrversibleMessage'),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: this.$t('general.ouiSupprimer'),
          }).then((result) => {
            if (result.isConfirmed) {
              historiqueRessource.destroy(id).then(response => {
                this.$message({
                  type: 'success',
                  message: this.$t('historique.suppressionEffectue'),
                });
                this.disableHistoryAdd = (this.historiques !== null ? this.historiques.length : 0) === 3;
                this.getParcelle();
              }).catch(error => {
                console.log(error);
              }).finally(() => {
                this.disableHistoryAdd = (this.historiques !== null ? this.historiques.length : 0) === 3;
              });
            }
          });
        }
      }).catch(() => {
        this.$message({
          type: 'info',
          message: 'Authorisation requise pour effectuer cette opération',
        });
      });
    },
    async initHistorique(id){
      const { data } = await historiqueRessource.list(id);
      this.historiques = data[0];
    },
    checkPermission,
    checkRole,
    isEmptyArray(array){
      if (typeof array !== 'undefined' && array.length === 0) {
        return true;
      }
    },
    getLotLink(id){
      this.$router.push({ path: '/lots/' + id });
    },
    async getGroupementsList() {
      const { data } = await groupementResource.list(this.query);
      this.groupements = data;
      var _reg = {};
      data.forEach(groupement => {
        _reg[groupement.id] = groupement.nom;
      });
      this.nomGroupements = _reg;
    },
    async getParcelles() {
      const { data } = await parcelleResource.get(this.$route.params.id);
      this.groupements = data;
      console.log('here is the groupement', this.groupements);
      var _reg = {};
      data.forEach(groupement => {
        _reg[groupement.id] = groupement.nom;
      });
      this.nomGroupements = _reg;
    },
    PrintProductor(){
      var routeData = this.$router.resolve({ path: '/producteur/' + this.$route.params.id });
      window.open(routeData.href, '_blank');
    },
    navigateParcelle(id){
      // var routeData = this.$router.resolve({ path: '/parcelle/' + this.$route.params.id });
      // window.open(routeData.href, '_blank');
      this.$router.push({ path: '/parcelles/' + id });
    },
    navigateImpression(){
      var routeData = this.$router.resolve({ path: '/parcelle/' + this.$route.params.id });
      window.open(routeData.href, '_blank');
    },
    async getVillagesList() {
      const { data } = await villageResource.list(this.query);
      this.villages = data;
      var _reg = {};
      data.forEach(village => {
        _reg[village.id] = village.nom;
      });
      this.nomVillages = _reg;
    },
    async getRecoltes() {
      const { data } = await recoltesResource.get(this.$route.params.id);
      console.log(data);
    },
    async getParcelle(id = this.$store.getters.camp_id) {
      var obj = {};
      this.loading = true;
      this.camp_id = id;
      obj = { keyword: this.$route.params.code, campagne_agricole_id: id };
      await this.getProducteur(id);
      const { data } = await parcelleResource.list(obj);
      console.log('LA PARCELLE ', data[0]);
      // this.camp_id = data[0] ? data[0].parcelle.cmp_id : id;
      this.parce_id = data[0].id;
      var recoltes_data = [];
      var controles_data = [];
      var audits_data = [];
      var recoltes = await recoltesResource.get(data[0] ? data[0].id : {});
      var controles = await parcelleControle.get(data[0] ? data[0].id : {});
      var audits = await parcelleAudit.get(data[0] ? data[0].id : {});
      // console.log('TYPEOF : ', audits);
      console.log('RECOLTES : ', recoltes);
      console.log('CONTROLES: ', controles);
      console.log('AUDITS: ', audits);
      if (recoltes.length > 0){
        for (let index = 0; index < Object.values(recoltes[0]).length; index++) {
          var element = Object.values(recoltes[0])[index];
          console.log('ELEMENT : ');
          for (let i = 0; i < element.length; i++) {
            var individual_data = element[i];
            recoltes_data.push(individual_data);
          }
          console.log('MEGBE');
        }
      }
      this.recoltes = recoltes_data;

      if (controles.data.length > 0){
        for (let index = 0; index < Object.values(controles.data).length; index++) {
          var controle_element = Object.values(controles.data)[index];
          console.log('controle_interne : ', controle_element);
          // controles_data.push(controle_element);
          for (let i = 0; i < Object.values(controle_element).length; i++) {
            var individual_controle = Object.values(controle_element)[i];
            console.log('INDIVIDUAL DATA : ', individual_controle[0]);
            controles_data.push(individual_controle[0]);
          }
        }
      }
      this.controles_internes = controles_data;

      if (audits.data.length > 0){
        for (let index = 0; index < Object.values(audits.data).length; index++) {
          var audit_element = Object.values(audits.data)[index];
          console.log('audit : ', audit_element);
          for (let i = 0; i < Object.values(audit_element).length; i++) {
            var individual_audit = Object.values(audit_element)[i];
            console.log('INDIVIDUAL AUDIT DATA : ', individual_audit[0]);
            audits_data.push(individual_audit[0]);
          }
        }
      }
      this.audits = audits_data;

      console.log('LE FINAL : ', controles_data);
      console.log('LES CONTROLES : ', Object.keys(controles.data['0']));
      console.log('ffff - ', Object.keys(recoltes[0]));
      var donnees = {
        groupement: data[0] ? data[0].groupement : '',
        parcelle: data[0] ? data[0].parcelle : {},
        producteur: data[0] ? data[0].producteur_data : {},
        controleur: data[0] ? data[0].controleur : {},
        commune: data[0] ? data[0].parcelle.commune : {},
        zone: data[0] ? data[0].parcelle.zone : '',
        region: data[0] ? data[0].parcelle.region : '',
        historiques: data[0] ? data[0].parcelle.historiques : [],
        produits: data[0] ? data[0].parcelle.produits : [],
        acc_saison: data[0] ? data[0].parcelle.acc_saison : {},
        cult_zn_tp: data[0] ? data[0].parcelle.cult_zn_tp : {},
        date_dernier_usage: data[0] ? data[0].parcelle.date_dernier_usage : {},
        nbr_arb: data[0] ? data[0].parcelle.nbr_arb : 0,
        village: data[0] ? data[0].parcelle.village : '',
        observation: data[0] ? data[0].parcelle.observation : '',
        controle_interne: data[0] ? data[0].controle_interne : '',
        controle_interne_data: data[0] ? data[0].controle_interne_data ? data[0].controle_interne_data : [] : {},
        is_audit: !!audits_data.length,
      };
      // this.histInfos = [
      //   {
      //     'libelle': 'Code    :',
      //     'valeur': data[0].parcelle.code,
      //   },
      // ];
      this.historiques = donnees.historiques;
      this.currentProducteur = donnees.producteur;
      this.disableHistoryAdd = this.historiques.length === 3;
      this.parcelle = donnees.parcelle;
      this.parcelle_id = this.$route.params.id;

      this.coordonnees_parcelle = this.coordonneesList(this.parcelle.coordonnees);
      console.log('_____this.coordonnees_parcelle____', this.coordonnees_parcelle[0]);
      console.log('le producteur', this.currentProducteur);
      this.list = this.historiques.map(v => {
        this.$set(v, 'edit', false);// https://vuejs.org/v2/guide/reactivity.html
        v.originalCulturePratique = v.culture_pratique; //  will be used when user click the cancel botton
        return v;
      });
      const produit = data[0] ? (data[0].parcelle.produits[0] ? data[0].parcelle.produits[0].nom : '') : '';
      console.log('PRODUIT DE CETTE PARCELLE', data[0] ? data[0].parcelle : {});
      if (data[0]){
        this.parcinfos = [
          {
            'libelle': 'Code    :',
            'valeur': data[0].parcelle.code,
          },
          {
            'libelle': 'Superficie    :',
            'valeur': data[0].parcelle.superficie,
          },
          {
            'libelle': 'Libelle    :',
            'valeur': data[0].parcelle.libelle,
          },
          {
            'libelle': 'Pdt cultivé    :',
            'valeur': produit,
          },
          {
            'libelle': 'statut    :',
            'valeur': data[0].parcelle.statut,
          },
          {
            'libelle': 'Observation    :',
            'valeur': data[0].parcelle.observation,
          },
          {
            'libelle': 'Estimation du rendement    :',
            'valeur': data[0].parcelle.estimation_rendement,
          },
          {
            'libelle': 'Culture au Nord    :',
            'valeur': data[0].parcelle.cult_nord,
          },
          {
            'libelle': 'Culture au Sud    :',
            'valeur': data[0].parcelle.cult_sud,
          },
          {
            'libelle': "Culture à l'Est    :",
            'valeur': data[0].parcelle.cult_est,
          },
          {
            'libelle': "Culture à l'ouest    :",
            'valeur': data[0].parcelle.cult_ouest,
          },
        ];
        this.organInfos = [
          {
            'libelle': 'Zone    :',
            'valeur': data[0].parcelle.zone,
          },
          {
            'libelle': 'Commune    :',
            'valeur': data[0].parcelle.commune,
          },
          {
            'libelle': 'Groupement',
            'valeur': data[0].groupement,
          },
          {
            'libelle': 'Village',
            'valeur': data[0].parcelle.village,
          },
        ];
      } else {
        this.parcinfos = [];
        this.organInfos = [];
      }
      // this.controleInfos = [
      //   {
      //     'libelle': 'Zone    :',
      //     'valeur': data[0].parcelle.zone,
      //   },
      //   {
      //     'libelle': 'Commune    :',
      //     'valeur': data[0].parcelle.commune,
      //   },
      //   {
      //     'libelle': 'Groupement',
      //     'valeur': data[0].groupement,
      //   },
      //   {
      //     'libelle': 'Village',
      //     'valeur': data[0].parcelle.village,
      //   },
      // ];
      this.data = donnees;
      console.log('la parcelle : ', data);
      this.fullscreenLoading = false;
      console.log('le loading : ', this.fullscreenLoading);
      this.date = moment().format('DD-MM-YYYY');
      this.loading = false;
      this.parcelleEditDisable = false;

      this.url = 'producteurs_change_profile/' + this.currentProducteur.id;
      console.log('the url', this.url);
    },
    coordonneesList(parccelleCoords){
      var cords = [];
      parccelleCoords.forEach(element => {
        cords.push([element.latitude, element.longitude]);
      });
      var lastValue = cords.shift(); // Retire la dernière valeur de la liste
      cords.push(lastValue);
      return cords;
    },
    async getProducteur(id = null){
      var obj = {};
      if (id !== null){
        console.log(' ID SET ', id);
        obj = { keyword: this.$route.params.code, campagne_agricole_id: id };
      } else {
        obj = { keyword: this.$route.params.code };
      }
      const { data } = await parcelleResource.list(obj);
      console.log(' GET AFTER ID ', data);
      if (data.length === 0){
        this.currentProducteur = {};
      } else {
        this.currentProducteur = data[0].producteur_data;
      }
      console.log('CURRENT PRODUXTEUR ', this.currentProducteur);
    },
    deleteProdcteur(){
      const producteurRessource = new Resource('producteurs');

      this.$confirm(this.$t('campagneagricole.deleteWarn') + ' ' + name + '. ' + this.$t('general.deleteContinue'), 'Warning', {
        confirmButtonText: 'Oui',
        cancelButtonText: this.$t('general.cancel'),
        type: 'warning',
      }).then(() => {
        this.$swal({
          title: this.$t('general.SureQuestion'),
          text: this.$t('general.irrversibleMessage'),
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: this.$t('general.ouiSupprimer'),
        }).then((result) => {
          if (result.isConfirmed){
            producteurRessource.destroy(this.currentProducteur.id + '/delete_profile')
              .then(response => {
                this.$message({
                  type: 'success',
                  message: 'Supprimé avec succès',
                });
                this.getProducteur();
              })
              .catch(error => {
                console.log(error);
              })
              .finally(() => {
              });
          }
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.edit-input {
  padding-right: 100px;
}
.cancel-btn {
  position: absolute;
  right: 15px;
  top: 10px;
}
.dialog-footer {
  text-align: left;
  padding-top: 0;
  margin-left: 150px;
}
.app-container {
  flex: 1;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;
  .block {
    float: left;
    min-width: 250px;
  }
  .clear-left {
    clear: left;
  }
}

.el-row {
  margin-bottom: 20px;
  &:last-child {
    margin-bottom: 0;
  }
}
.el-col {
  border-radius: 4px;
}

.bg-purple {
  background: #d3dce6;
}
.bg-purple-light {
  background: #e5e9f2;
}
.basemap {
  height: 400px;
}
#mapContainer {
  height: 100%;
  width: 100%;
}

#map-container {
  height: 70vh;
  width: auto;
}

.mapboxgl-canvas {
  width: 70vh!important;
  height: auto!important;
}

.btn-control {
  font: bold 12px/20px 'Helvetica Neue', Arial, Helvetica, sans-serif;
  background-color: #3386c0;
  color: #fff;
  position: absolute;
  top: 20px;
  left: 50%;
  z-index: 1;
  border: none;
  width: 200px;
  margin-left: -100px;
  display: block;
  cursor: pointer;
  padding: 10px 20px;
  border-radius: 3px;
}
.btn-control:hover {
  background-color: #4ea0da;
}
.pan-thumb {
  width: 150px;
  height: 150px;
  background-position: center center;
  background-size: cover;
  border-radius: 75px;
  overflow: hidden;
  margin-bottom: 20px;
  // position: absolute;
  background-image:url('https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg');
  // transform-origin: 95px 40px;
  // transition: all 0.3s ease-in-out;
  background-repeat:no-repeat;
}
.user-follow {
  padding-top: 20px;
}
</style>
